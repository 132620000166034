// Main imports
import { Fragment } from 'react';
import { addCSSClassName, generateUrlPath } from './helpers';
import pagesCSS from '../../Components/Pages/Pages.module.scss';

import logo from '../../assets/images/logo-cut-white-clean.jpg';
import navigation from './navigation';

// Apartment images
import imgApartment0 from '../images/Rooms/Apartment/image_00.jpg';
import imgApartment1 from '../images/Rooms/Apartment/image_01.jpg';
import imgApartment2 from '../images/Rooms/Apartment/image_02.jpg';
import imgApartment3 from '../images/Rooms/Apartment/image_03.jpg';
import imgApartment4 from '../images/Rooms/Apartment/image_04.jpg';
import imgApartment5 from '../images/Rooms/Apartment/image_05.jpg';
import imgApartment6 from '../images/Rooms/Apartment/image_06.jpg';
import imgApartment7 from '../images/Rooms/Apartment/image_07.jpg';
import imgApartment8 from '../images/Rooms/Apartment/image_08.jpg';
import imgApartment9 from '../images/Rooms/Apartment/image_09.jpg';
import imgApartment10 from '../images/Rooms/Apartment/image_10.jpg';
import imgApartment11 from '../images/Rooms/Apartment/image_11.jpg';
import imgApartment12 from '../images/Rooms/Apartment/image_12.jpg';
import imgApartment13 from '../images/Rooms/Apartment/image_13.jpg';
import imgApartment14 from '../images/Rooms/Apartment/image_14.jpg';

// Triple Room images
import imgTripleRoom0 from '../images/Rooms/TripleRoom/image_00.jpg';
import imgTripleRoom1 from '../images/Rooms/TripleRoom/image_01.jpg';
import imgTripleRoom2 from '../images/Rooms/TripleRoom/image_02.jpg';
import imgTripleRoom3 from '../images/Rooms/TripleRoom/image_03.jpg';
import imgTripleRoom4 from '../images/Rooms/TripleRoom/image_04.jpg';
import imgTripleRoom5 from '../images/Rooms/TripleRoom/image_05.jpg';
import imgTripleRoom6 from '../images/Rooms/TripleRoom/image_06.jpg';
import imgTripleRoom7 from '../images/Rooms/TripleRoom/image_07.jpg';
import imgTripleRoom8 from '../images/Rooms/TripleRoom/image_08.jpg';
import imgTripleRoom9 from '../images/Rooms/TripleRoom/image_09.jpg';
import imgTripleRoom10 from '../images/Rooms/TripleRoom/image_10.jpg';
import imgTripleRoom11 from '../images/Rooms/TripleRoom/image_11.jpg';
import imgTripleRoom12 from '../images/Rooms/TripleRoom/image_12.jpg';
import imgTripleRoom13 from '../images/Rooms/TripleRoom/image_13.jpg';
import imgTripleRoom14 from '../images/Rooms/TripleRoom/image_14.jpg';
import imgTripleRoom15 from '../images/Rooms/TripleRoom/image_15.jpg';
import imgTripleRoom16 from '../images/Rooms/TripleRoom/image_16.jpg';
import imgTripleRoom17 from '../images/Rooms/TripleRoom/image_17.jpg';
import imgTripleRoom18 from '../images/Rooms/TripleRoom/image_18.jpg';

// Double room images
import imgDoubleRoom0 from '../images/Rooms/DoubleRoom/image_00.jpg';
import imgDoubleRoom1 from '../images/Rooms/DoubleRoom/image_01.jpg';
import imgDoubleRoom2 from '../images/Rooms/DoubleRoom/image_02.jpg';
import imgDoubleRoom3 from '../images/Rooms/DoubleRoom/image_03.jpg';
import imgDoubleRoom4 from '../images/Rooms/DoubleRoom/image_04.jpg';
import imgDoubleRoom5 from '../images/Rooms/DoubleRoom/image_05.jpg';
import imgDoubleRoom6 from '../images/Rooms/DoubleRoom/image_06.jpg';

// Budget room images
import imgBudgetRoom0 from '../images/Rooms/BudgetRoom/image_00.jpg';
import imgBudgetRoom1 from '../images/Rooms/BudgetRoom/image_01.jpg';
import imgBudgetRoom2 from '../images/Rooms/BudgetRoom/image_02.jpg';
import imgBudgetRoom3 from '../images/Rooms/BudgetRoom/image_03.jpg';
import imgBudgetRoom4 from '../images/Rooms/BudgetRoom/image_04.jpg';


export const homePageContent = {
   quoteHeading: {
	BG: '"За морето е характерно да прониква в душата"',
	EN: '"It is characteristic of the sea to penetrate into the soul"',
	 },

   quoteAuthor: {
	BG: 'Хана Кант',
	EN: 'Hannah Kant',
	 },

  hotelDescriptionHeading: {
    BG: (
      <span>
	Къща за гости "Вивиан" - гр.Китен, ул."Акация"№2  <br />
	В непосредствена близост до кметството на гр.Китен <br />
	РЕНОВИРАНА 2022г.
      </span>
    ),
    EN: (
      <span>
	Guest House "Vivian" - Kiten, 2 Acacia Str.  <br />
	In close proximity to the town hall of Kiten <br />
	RENOVATED in 2022.
      </span>
    ),
  },

 hotelDescriptionText:{
	BG:(
	 <Fragment>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
	    Къща за гости "Вивиан" е разположена на 300 метра от прекрасния Южен плаж  и на 500м.от
плаж Атлиман. В сърцето на града и в същото време на тиха и спокойна улица, което ще Ви
предостави отлични условия за морска почивка. Къщата е реновирана и изцяло обзаведена през
2022г.
        </p>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
	    Гостувайки при нас, ще се потопите в очарователната атмосфера на малкият семеен хотел - с
персонално отношение към всеки  гост, уют и спокойствие.Ние ще се постараем да се
почувствате по възможно най- комфортния начин и да се насладите на незабравими морски
изживявания.
        </p>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
	    Тук ще откриете любимото си място за почивка на Черноморието . Разполагаме с помещения
подходящи за всеки.
        </p>
	 </Fragment>
	 ),

	EN:(
	 <Fragment>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
	    Vivian Guest House is located 300 meters from the beautiful South Beach and 500 meters
from Atliman Beach. Located in the heart of the city and at the same time on a quiet
street, which will provide you with excellent conditions for a sea holiday. The house was
renovated and fully furnished in 2022.
        </p>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
	    Visiting us, you will immerse yourself in the charming atmosphere of the small family
guest house - with a personal attitude to each guest, comfort and tranquility. We will try
to make you feel as comfortable as possible and enjoy unforgettable sea experiences.
        </p>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
	    Here you will find your favorite vacation spot on the Black Sea coast. We have rooms
suitable for everyone.
        </p>
	 </Fragment>
	 ),
 },

  tables: [
	{
	heading: {
		BG: 'Ценоразпис (на нощувка)',
		EN: 'Pricing (per night)',
		},

	listDescription: [
		{
		BG: 'Самостоятелно ползващ помещение, заплаща 80% от цената му при пълен капацитет!',
		EN: 'Independent user of a room, pays 80% of its price at full capacity!',
		},
		{
		BG: 'Деца от 0 до 6 години се настаняват безплатно, но без ползане на самостоятелно легло!',
		EN: 'Children from 0 to 6 years stay for free, but without the use of a separate bed!',
		},
		{
		BG: 'Настаняването на пети човек в апартамента е на разтегателен диван!',
		EN: 'The accommodation of the fifth person in the apartment is on a sofa bed!',
		},
		{
		BG: 'Паркоместа се заявяват предварително, и се осигуряват до изчерпването им. Цена за едно денонощие 8,00лв.!',
		EN: 'Parking spaces are requested in advance and are secured until they are exhausted. Price for 24 hours is BGN 8.00!',
		},
		{
		BG: 'Допълнително легло в помещение с такава възможност - 15,00лв.с вкл.закуска!',
		EN: 'An additional bed in a room with such an option - BGN 15.00 including breakfast!',
		},
		{
		BG: 'Закуската е на блок маса, но при запълване по-малко от 10% от местата се приготвя индивидуална закуска!',
		EN: 'Breakfast is at a buffet table, but if less than 10% of the seats are filled, an individual breakfast is prepared!',
		},
		{
		BG: 'Домашни любимци не се допускат!',
		EN: 'Pets are not allowed!',
		},
	 ],

    tableContent: {
        headerText: [
		{
		BG: '',
		EN: '',
		},
		{
		BG: '01.06.2025 - 15.06.2025',
		EN: '01.06.2025 - 15.06.2025',
		},
		{
		BG: '16.06.2025 - 30.06.2025',
		EN: '16.06.2025 - 30.06.2025',
		},
		{
		BG: '01.07.2025 - 25.08.2025',
		EN: '01.07.2025 - 25.08.2025',
		},
		{
		BG: '26.08.2025 - 15.09.2025',
		EN: '26.08.2025 - 15.09.2025',
		},
        ],
        mainContentRows: [
		[
		{
		BG: '',
		EN: '',
		},
		{
		BG: '',
		EN: '',
		},
		{
		BG: 'с включена закуска',
		EN: 'with breakfast included',
		},
		{
		BG: 'без включена закуска',
		EN: 'without breakfast included',
		},
		{
		BG: 'с включена закуска',
		EN: 'with breakfast included',
		},
		{
		BG: 'без включена закуска',
		EN: 'without breakfast included',
		},
		{
		BG: 'с включена закуска',
		EN: 'with breakfast included',
		},
		{
		BG: 'без включена закуска',
		EN: 'without breakfast included',
		},
		{
		BG: 'с включена закуска',
		EN: 'with breakfast included',
		},
		{
		BG: 'без включена закуска',
		EN: 'without breakfast included',
		},
		],

		[
		{
		BG: 'Двойна стая с две отделни легла (цена за двама)',
		EN: 'Double room with two separate beds (price for two)',
		},
		{
		BG: '',
		EN: '',
		},
		{
		BG: '70.00 лв.',
		EN: 'BGN 70.00',
		},
		{
		BG: '60.00 лв.',
		EN: 'BGN 60.00',
		},
		{
		BG: '80.00 лв.',
		EN: 'BGN 80.00',
		},
		{
		BG: '70.00 лв.',
		EN: 'BGN 70.00',
		},
		{
		BG: '100.00 лв.',
		EN: 'BGN 100.00',
		},
		{
		BG: '90.00 лв.',
		EN: 'BGN 90.00',
		},
		{
		BG: '80.00 лв.',
		EN: 'BGN 80.00',
		},
		{
		BG: '70.00 лв.',
		EN: 'BGN 70.00',
		},
		],

		[
		{
		BG: 'Тройна стая  (цена за трима)',
		EN: 'Triple room (price for three)',
		},
		{
		BG: '',
		EN: '',
		},
		{
		BG: '105.00 лв.',
		EN: 'BGN 105.00',
		},
		{
		BG: '90.00 лв.',
		EN: 'BGN 90.00',
		},
		{
		BG: '120.00 лв.',
		EN: 'BGN 120.00',
		},
		{
		BG: '105.00 лв.',
		EN: 'BGN 105.00',
		},
		{
		BG: '150.00 лв.',
		EN: 'BGN 150.00',
		},
		{
		BG: '135.00 лв.',
		EN: 'BGN 135.00',
		},
		{
		BG: '120.00 лв.',
		EN: 'BGN 120.00',
		},
		{
		BG: '105.00 лв.',
		EN: 'BGN 105.00',
		},
		],

		[
		{
		BG: 'Апартамент (макс.капацитет 5 души) *едното място е на разтегателен диван',
		EN: 'Apartment (max. capacity 5 people) *one seat is on a sofa bed',
		},
		{
		BG: '',
		EN: '',
		},
		{
		BG: '150.00 лв.',
		EN: 'BGN 150.00',
		},
		{
		BG: '125.00 лв.',
		EN: 'BGN 125.00',
		},
		{
		BG: '200.00 лв.',
		EN: 'BGN 200.00',
		},
		{
		BG: '175.00 лв.',
		EN: 'BGN 175.00',
		},
		{
		BG: '230.00 лв.',
		EN: 'BGN 230.00',
		},
		{
		BG: '205.00 лв.',
		EN: 'BGN 205.00',
		},
		{
		BG: '200.00 лв.',
		EN: 'BGN 200.00',
		},
		{
		BG: '175.00 лв.',
		EN: 'BGN 175.00',
		},
		],

		[
		{
		BG: 'Бюджетна стая *без климатик със спалня 140/200 (цена за двама)',
		EN: 'Budget room *without air conditioning with bedroom 140/200 (price for two)',
		},
		{
		BG: '',
		EN: '',
		},
		{
		BG: '60.00 лв.',
		EN: 'BGN 60.00',
		},
		{
		BG: '50.00 лв.',
		EN: 'BGN 50.00',
		},
		{
		BG: '70.00 лв.',
		EN: 'BGN 70.00',
		},
		{
		BG: '60.00 лв.',
		EN: 'BGN 60.00',
		},
		{
		BG: '80.00 лв.',
		EN: 'BGN 80.00',
		},
		{
		BG: '70.00 лв.',
		EN: 'BGN 70.00',
		},
		{
		BG: '70.00 лв.',
		EN: 'BGN 70.00',
		},
		{
		BG: '60.00 лв.',
		EN: 'BGN 60.00',
		},
		],

		[
		{
		BG: 'Дете  до 11 годишна възраст настанено на самостоятелно легло',
		EN: 'Child up to 11 years of age accommodated in a separate bed',
		},
		{
		BG: '',
		EN: '',
		},
		{
		BG: '17.50 лв.',
		EN: 'BGN 17.50',
		},
		{
		BG: '15.00 лв.',
		EN: 'BGN 15.00',
		},
		{
		BG: '20.00 лв.',
		EN: 'BGN 20.00',
		},
		{
		BG: '17.50 лв.',
		EN: 'BGN 17.50',
		},
		{
		BG: '25.00 лв.',
		EN: 'BGN 25.00',
		},
		{
		BG: '22.50 лв.',
		EN: 'BGN 22.50',
		},
		{
		BG: '20.00 лв.',
		EN: 'BGN 20.00',
		},
		{
		BG: '17.50 лв.',
		EN: 'BGN 17.50',
		},
		],
 ],
    },
	},
  ],


  terms: {
    heading: {
	BG: 'Условия за използване',
	EN: 'Terms Of Use',
    },

    content: {
      BG: (
        <Fragment>
          <p>
            &ndash; Самостоятелно ползващ помещение заплаща 80% от цената му при пълен капацитет на същото!
          </p>
          <p>
            &ndash; Деца от 0 до 6 години се настаняват безплатно но без ползване на самостоятелно легло!
          </p>
          <p>
            &ndash; Настаняването на пети човек в апартамента е на разтегателен диван!
          </p>
          <p>
            &ndash; Паркоместа се заявяват предварително и се осигуряват до изчерпването им.
              <br />&nbsp;&nbsp;Цена за едно денонощие 8.00 лв. !
          </p>
          <p>
            &ndash; Допълнително легло в помещение с такава възможност – 15.00 лв. с вкл. закуска!
          </p>
          <p>
            &ndash; Закуската е на блок маса, но при запълване по-малко от 10% от местата се приготвя индивидуална закуска!
          </p>
          <p>
            &ndash; Обявените цени са в български лева с включени данъци и такси!
          </p>
          <p>
            &ndash; Домашни любимци не се допускат!
          </p>
        </Fragment>
      ),

      EN: (
        <Fragment>
          <p>
            &ndash; Independent user of a room, pays 80% of its price at full capacity!
          </p>
          <p>
            &ndash; Children from 0 to 6 years stay for free, but without the use of a separate bed!
          </p>
          <p>
            &ndash; The accommodation of the fifth person in the apartment is on a sofa bed!
          </p>
          <p>
            &ndash; Parking spaces are requested in advance and are secured until they are exhausted.
              <br />&nbsp;&nbsp;Price for 24 hours is BGN 8.00!
          </p>
          <p>
            &ndash; An additional bed in a room with such an option - BGN 15.00 including breakfast!
          </p>
          <p>
            &ndash; Breakfast is at a buffet table, but if less than 10% of the seats are filled an individual breakfast is prepared!
          </p>
          <p>
            &ndash; The prices are in bulgarian currency. Taxes included!
          </p>
          <p>
            &ndash; Pets are not allowed!
          </p>
        </Fragment>
      ),
    },
  },
};

export const aboutPageContent = {

 blockOurHotelHeading: {
	BG: 'Нашата къща',
	EN: 'Our Guest House',
	 },

 blockOurHotelText:{
	BG:(
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Гостувайки при нас ще се потопите в очарователната атмосфера на малкия семеен хотел -
гостоприемство, уют, спокойствие. Екипът ни ще се постарае да се почуствате по възможно
най-комфортния начин, и да се насладите на хармония и незабравими морски преживявания.
         </p>
	 ),

	EN:(
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Visiting us you will immerse you into the charming atmosphere of the small family guest
house - hospitality, comfort, tranquility. Our team will make you feel as comfortable as
possible, and enjoy the harmony and unforgettable sea experiences.
         </p>
	 ),
 },

 blockLocationHeading: {
	BG: 'Местоположение',
	EN: 'Location',
	 },

 blockLocationText:{
	BG:(
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Къща за гости Вивиан е разположен на 3 минутки от прекрасния Южен плаж на Китен и на 500м от
плаж "Атлиман" /Северният плаж/. В центъра на града и в същото време на китна, тиха и
спокойна уличка, което ще Ви предостави отлични условия за морска почивка.
         </p>
	 ),

	EN:(
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Guest House Vivian is located 3 minutes from the beautiful South Beach of Kiten and 500
meters from the beach "Atliman" / North Beach /. Located in the city center and at the same
time on a beautiful, quiet and peaceful street, which will provide you with excellent
conditions for a sea holiday.
         </p>
	 ),
 },

 blockFindHereHeading: {
	BG: 'Какво ще откриете тук',
	EN: 'What you will find here',
	 },

 blockFindHereText:{
	BG:(
	 <Fragment>
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Тук Вие ще откриете любимото си място за почивка на Черноморието. Разполагаме с помещения
подходящи за всеки вкус. Апартаменти, двойни, тройни, четворни стаи, както и бюджетни
стаи.
         </p>
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Разполагаме със следните помещения : Апартамент, двойни стаи, обикновени тройни стаи,
тройни стаи лукс, както и бюджетни стаи.
         </p>
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Всяка стая разполага със собствена тераса, климатик (с изключение на бюджетните стаи),
санитарен възел, чисто нови комфортни легла, LED 32" телевизор с много програми, безплатен
WIFI и хладилник.
         </p>
	 </Fragment>
	 ),

	EN:(
	 <Fragment>
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    Here you will find your favorite vacation spot on the Black Sea coast. We have rooms
suitable for every taste. Apartments, double, triple, quadruple rooms and budget rooms.
         </p>
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	    We have the following rooms: Apartment, double rooms, ordinary triple rooms, luxury triple
rooms, as well as budget rooms.
         </p>
         <p className={addCSSClassName(pagesCSS, 'pages__text')}>
	     Each room has its own balcony, air conditioning (excluding budget rooms), bathroom, brand
 new comfortable beds, LED 32" TV with many programs, free WIFI and refrigerator.
         </p>
	 </Fragment>
	 ),
 },

 blockPersonalAttitudeHeading: {
	BG: 'Персонално отношение',
	EN: 'Personal attitude',
	 },

 blockPersonalAttitudeText:{
	BG:(
         <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
	    Тук ще получите индивидуално отношение, усещане за спокойствие и уют и не на последно място
вкусна, домашно приготвена с много любов закуска.
         </p>
	 ),

	EN:(
         <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
	    Here you will receive an individual attitude towards you, a feeling of calm and comfort and,
last but not least, delicious, home-made, prepared with much love, breakfast.
         </p>
	 ),
 },

 blockRoomsHeading: {
	BG: 'Помещения',
	EN: 'Rooms',
	 },

 blockRoomsText:{
	BG:(
         <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
	    Уютни помещения, оборудвани с всичко необходимо.
         </p>
	 ),

	EN:(
         <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
	    Cozy rooms equipped with everything you need.
         </p>
	 ),
 },

 blockQualityChecksHeading: {
	BG: 'Проверка на качеството',
	EN: 'Quality control',
	 },

 blockQualityChecksText:{
	BG:(
         <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
	    За да осигурим максимално удовлетворение от престоя на гостите, ние многократно, но
деликатно ще проверяваме качеството на предоставените услуги.
         </p>
	 ),

	EN:(
         <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
	    In order to ensure maximum satisfaction with the stay of the guests, we will repeatedly but
delicately check the quality of the services provided.
         </p>
	 ),
 },
};

export const pageNotFoundContent = {
  heading: {
    BG: 'Страницата, която търсихте не беше намерена',
    EN: 'The page you were looking for was not found',
  },

  text: {
    BG: 'Моля опитайте отново по-късно или влезте в друга страница',
    EN: 'Please try again later or enter another page',
  },

  btnText: {
    BG: 'Към началото',
    EN: 'Back to homepage',
  },
};

export const thankYouPageContent = {
  BG: {
    heading: 'Благодарим за вашето съобщение!',
    text: 'Ще се свържем с вас във възможно най-кратък срок (обикновенно 1 до 2 дни)',
    btnText: 'Към началото',
  },

  EN: {
    heading: 'Thank you for your message!',
    text: 'We will contact you as soon as possible (usually 1 до 2 дни)',
    btnText: 'Back to homepage',
  },
};

export const logoContent = {
  src: logo,
  alt: {
    BG: 'Лого на Къща за Гости Вивиан',
    EN: "Guest house Vivian's logo",
  },
};

export const promoContent = {
  text: {
    BG: 'Добре дошли в Къща за Гости Вивиан',
    EN: 'Welcome to Guest House Vivian',
  },
};

export const locationMapContent = {
  mapTitleAttr: {
    BG: 'Местоположение',
    EN: 'Location',
  },

  contactInfoHeading: {
    BG: 'Контактна информация',
    EN: 'Contact Info',
  },
};

const roomsHeadingUrlInfo = [
  {
    heading: 'Apartment',

    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },

  {
    heading: 'Budget Room',

    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },

  {
    heading: 'Double room',

    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },

  {
    heading: 'Triple room',
    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },
];

export const roomsUrls = roomsHeadingUrlInfo.map(cur => cur.getUrl());

export const specificRoomsContent = [

{
 heading: {
	BG: 'Апартамент',
	EN: 'Apartment',
	 },

 description:{
	BG:(
        <p>
	    Апартаментът разполага с две самостоятелни стаи отделени с врата, кухненски бокс, маса за
хранене, диван, два санитарни възела, два LED телевизора, безплатен Wi-Fi, хладилник,
климатици. Всяко от помещенията е със самостоятелна тераса. Подходящ за до 5 човека.
        </p>
	 ),

	EN:(
        <p>
	    The apartment has two separate rooms separated by a door, a kitchenette, a dining table, a sofa,
two bathrooms, two LED TVs, free Wi-Fi, a refrigerator, air conditioning. Each room 
has a private terrace. Suitable for up to 5 persons.
        </p>
	 ),
 },

     images: [
       imgApartment1,
       imgApartment2,
       imgApartment3,
       imgApartment4,
       imgApartment5,
       imgApartment6,
       imgApartment7,
       imgApartment8,
       imgApartment9,
       imgApartment10,
       imgApartment11,
       imgApartment12,
       imgApartment13,
       imgApartment14,
     ],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },

{
 heading: {
	BG: 'Двойна стая',
	EN: 'Double room',
	 },

 description:{
	BG:(
        <p>
	    Максимално настаняване до 2 души, възможност за спалня или две единични легла. Разполага
със собствена тераса, климатик, санитарен възел, чисто нови комфортни легла и обзавеждане,
LED телевизор с кабелна телевизия, хладилник мини-бар и безплатен WiFi.
        </p>
	 ),

	EN:(
        <p>
	    Maximum occupancy: up to 2 people, with the option of a double bed or two singles beds.
The room features: a private terrace, air-conditioning, a bathroom, comfortable beds, 
a LED TV with cable channels, a mini-bar refrigerator and free WiFi.
        </p>
	 ),
 },

     images: [
       imgDoubleRoom1,
       imgDoubleRoom2,
       imgDoubleRoom3,
       imgDoubleRoom4,
       imgDoubleRoom5,
       imgDoubleRoom6,
     ],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },

{
 heading: {
	BG: 'Тройна стая',
	EN: 'Triple room',
	 },

 description:{
	BG:(
        <p>
	    Настаняване до трима възрастни. Разполага със собствена тераса, климатик, санитарен
възел, чисто нови комфортни легла и обзавеждане, LED телевизор с кабелна телевизия,
хладилник мини-бар и безплатен WiFi.
        </p>
	 ),

	EN:(
        <p>
	    Accommodation for up to 3 adults with aditional (fold-out) bed, subject of availability or a baby crib
witch must be requested in advance and is provided on availability. The room features: a private terrace,
air-conditioning, a bathroom, comfortable beds, a LED TV with cable channels, a mini-bar refrigerator 
and free WiFi.
        </p>
	 ),
 },

     images: [
       imgTripleRoom1,
       imgTripleRoom2,
       imgTripleRoom3,
       imgTripleRoom4,
       imgTripleRoom5,
       imgTripleRoom6,
       imgTripleRoom7,
       imgTripleRoom8,
       imgTripleRoom9,
       imgTripleRoom10,
       imgTripleRoom11,
       imgTripleRoom12,
       imgTripleRoom13,
       imgTripleRoom14,
       imgTripleRoom15,
       imgTripleRoom16,
       imgTripleRoom17,
       imgTripleRoom18,
     ],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },

{
 heading: {
	BG: 'Бюджетна стая',
	EN: 'Budget room',
	 },

 description:{
	BG:(
        <p>
	    Бюджетната стая е подходяща за двама възрастни или възрастен с едно дете. Разполага със
спалня с размер 140/200см, със собствена тераса, санитарен възел, LED телевизор с
кабелна телевизия, хладилник мини-бар и безплатен WiFi.
        </p>
	 ),

	EN:(
        <p>
	    The budget room is suitable for two adults or an adult with one child. It features: a 140/200cm bed,
a private terrace, a bathroom, LED TV with cable channels, a mini-bar refrigerator and free WiFi.
The room doesn't feature air-conditioning.
        </p>
	 ),
 },

     images: [
       imgBudgetRoom1,
       imgBudgetRoom2,
       imgBudgetRoom3,
       imgBudgetRoom4,
     ],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },
];

export const roomPageContent = {
  descriptionLabel: {
    BG: 'Описание',
    EN: 'Description',
  },

  pricingLabel: {
    BG: 'Ценоразпис',
    EN: 'Pricing',
  },

  termsLabel: {
    BG: 'Условия',
    EN: 'Room Terms',
  },

  btnLabel: {
    BG: 'Резервирайте сега',
    EN: 'Book now',
  },
};

export const gridItemContent = {
  btnText: {
    BG: 'Преглед',
    EN: 'View',
  },
};

export const roomsContent = {
  heading: {
    BG: 'Нашите помещения',
    EN: 'Our rooms',
  },

  rooms: [
    {
      img: {
        src: imgApartment0,
        alt: {
          BG: 'Апартамент',
          EN: 'Apartment',
        },
      },

      heading: {
        BG: 'Апартамент',
        EN: 'Apartment',
      },

      prices: [150, 200],
      url: '/rooms/apartment',
    },
    
    {
      img: {
        src: imgTripleRoom0,
        alt: {
          BG: '',
          EN: '',
        },
      },

      heading: {
        BG: 'Тройна стая',
        EN: 'Triple room',
      },

      prices: [150, 200],
      url: '/rooms/triple-room',
    },

    {
      img: {
        src: imgDoubleRoom0,
        alt: {
          BG: '',
          EN: '',
        },
      },

      heading: {
        BG: 'Двойна стая',
        EN: 'Double room',
      },

      prices: [150, 200],
      url: '/rooms/double-room',
    },

    {
      img: {
        src: imgBudgetRoom0,
        alt: {
          BG: 'Бюджетна стая',
          EN: 'Budget room',
        },
      },

      heading: {
        BG: 'Бюджетна стая',
        EN: 'Budget room',
      },

      prices: [150, 200],
      url: '/rooms/budget-room',
    },
  ],
};

export const galleryContent = {
  heading: {
    BG: 'Нашата галерия',
    EN: 'Our gallery',
  },
};

export const contactsPageContent = {
  heading: {
    BG: 'Връзка с нас',
    EN: 'Contact us',
  },
};

export const headerContent = {
  navigation: {
    BG: navigation.BG,
    EN: navigation.EN,
  },
};

export const footerContent = {
  navigation: {
    BG: navigation.BG.find(menu => menu.name === 'footerNav'),
    EN: navigation.EN.find(menu => menu.name === 'footerNav'),
  },

  copyText: {
    BG: (
      <span>
        &copy;<span>{new Date().getFullYear()}</span> Всички права запазени.
      </span>
    ),

    EN: (
      <span>
        &copy;<span>{new Date().getFullYear()}</span> All Rights Reserved.
      </span>
    ),
  },
};

export const contactFormContent = {
  name: {
    BG: 'Име',
    EN: 'Your Name',
  },

  phone: {
    BG: 'Телефон',
    EN: 'Phone',
  },

  email: {
    BG: 'Имейл (По избор)',
    EN: 'Email (Optional)',
  },

  message: {
    BG: 'Съобщение',
    EN: 'Your message',
  },

  button: {
    text: {
      BG: 'Изпрати',
      EN: 'Send',
    },
  },
};

export const roomTermsContent = [
  {
    heading: {
      BG: 'Допълнително легло',
      EN: 'Extra bed',
    },

    description: {
      BG: 'Допълнителното легло се доплаща',
      EN: 'Extra bed is costs extra',
    },
  },

  {
    heading: {
      BG: 'Бебешка кошарка',
      EN: 'Baby cot',
    },

    description: {
      BG: 'Бебешката кошарка е безплатна, предоставя се с предварителна заявка, но до изчерпване на наличните бройки.',
      EN: 'The baby cot is free of charge, provided by prior request, but until the available quantities are exhausted.',
    },
  },

  {
    heading: {
      BG: 'Домашни любимци',
      EN: 'Pets',
    },

    description: {
      BG: 'Домашни любимци не се допускат.',
      EN: 'Pets are not allowed inside.',
    },
  },

  {
    heading: {
      BG: 'Паркинг',
      EN: 'Parking',
    },

    description: {
      BG: 'Платен паркинг - 8.00 лв. на денонощие - ограничен брой места.',
      EN: 'Paid parking - BGN 8.00 per 24 hours - limited number of places',
    },
  },
];

